import { CompetitionClientModel, SportClientModel } from '@sport1/types/sportData'

function getSportIdentifier({
    sport,
    competition,
}: {
    sport?: SportClientModel
    competition?: CompetitionClientModel
}): string | undefined {
    return sport?.sportGroup?.sportIdentifier || competition?.sportGroup?.sportIdentifier
}

export default getSportIdentifier
