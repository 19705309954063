import { useCallback } from 'react'
import { useTracking } from '@/utils/tracking/TrackingProvider'

const useDropdownTracking = () => {
    const { trackIvwInfo, trackIvwView } = useTracking()
    return useCallback(() => {
        trackIvwInfo({
            ivw_context: 'switch_dropdown',
        })
        trackIvwView({
            ivw_context: 'switch_dropdown',
        })
    }, [trackIvwInfo, trackIvwView])
}

export default useDropdownTracking
