import { TeamStandingClientModel } from '@sport1/types/sportData'

type Params = {
    topRankedRow: TeamStandingClientModel
    trailingRow: TeamStandingClientModel
}

// ((Team A wins - Team B won) + (Team B losses - Team A losses)) / 2
const calculateGamesBehind = ({ topRankedRow, trailingRow }: Params): string => {
    const { won: topWon, lost: topLost } = topRankedRow
    const { won: trailingWon, lost: trailingLost } = trailingRow
    if (!topWon || !topLost || !trailingWon || !trailingLost) return '-'

    const gamesBehind = (topWon - trailingWon + trailingLost - topLost) / 2
    return gamesBehind !== 0 ? `${gamesBehind}` : '-'
}

export default calculateGamesBehind
