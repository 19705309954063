import { CompetitionClientModel } from '@sport1/types/sportData'

const USES_GRAND_SLAMS_STANDINGS_FORMAT = [
    'sr:competition:597', //Grand Slam of Darts competition id
    'sr:competition:1744', //World Cup of Darts competition id
]
const getDartsStandingsFormat = (
    competition?: CompetitionClientModel
): 'DEFAULT' | 'GRAND_SLAMS' =>
    competition?.id && USES_GRAND_SLAMS_STANDINGS_FORMAT.includes(competition?.id)
        ? 'GRAND_SLAMS'
        : 'DEFAULT'

export default getDartsStandingsFormat
