import { MatchTickerClientModel } from '@sport1/types/sportData'
import Config from '@/utils/Config'
import S1Error from '@/utils/errors/S1Error'
import getJson from '@/utils/fetch/getJson'

export const rejectLivetickerMatch = <T>(sportIdentifier: string, matchId: string): Promise<T> => {
    return Promise.reject(
        new S1Error(
            404,
            `Fetching match data failed! sportIdentifier: ${sportIdentifier}, matchId: ${matchId}`
        )
    )
}

const fetchMatch = async (
    sportIdentifier: string,
    matchId: string
): Promise<MatchTickerClientModel> => {
    const matchUrl = `${Config.CMS_API}/v2/de/${sportIdentifier}/ticker/${matchId}`
    try {
        return (await getJson(matchUrl)) as MatchTickerClientModel
    } catch (error) {
        return rejectLivetickerMatch(sportIdentifier, matchId)
    }
}

export default fetchMatch
