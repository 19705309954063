import { HorizontalCardProps, TeaserCardProps } from '@sport1/types/web'
import { EDITORIAL_TEASER_CARD_MAX_ITEMS, TEASER_CARD_MAX_ITEMS } from '@/utils/content/constants'

export const prepareTeasers = (teaserComponent: TeaserCardProps | HorizontalCardProps) => {
    if (!teaserComponent?.content || teaserComponent.content?.length === 0) return []
    const { content } = teaserComponent
    const isEditorialContent = teaserComponent?.meta?.tracking?.find(
        prop => prop.key === 'component_type' && prop.value === 'EDITORIAL_CONTENT'
    )
    const maxItemsCount = isEditorialContent
        ? EDITORIAL_TEASER_CARD_MAX_ITEMS
        : TEASER_CARD_MAX_ITEMS

    return [
        ...content.slice(
            0,
            Math.min(teaserComponent.numberOfContentsToShow || maxItemsCount, maxItemsCount)
        ),
    ]
}
