import React, { useCallback } from 'react'
import getJson from '@/utils/fetch/getJson'

export type UseFetchReturnProps<T> = {
    data?: T
    error?: Error
    fetchData: (fetchUrl?: string) => void
    loading: boolean
}

const useFetch = <T>(url?: string, startData?: T): UseFetchReturnProps<T> => {
    const [data, setData] = React.useState<T | undefined>(startData)
    const [error, setError] = React.useState<Error | undefined>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const fetchComponentData = useCallback(
        (fetchUrl?: string): void => {
            if (fetchUrl || url) {
                setLoading(true)
                setError(undefined)
                getJson(fetchUrl ?? url)
                    .then(setData)
                    .catch(setError)
                    .finally(() => setLoading(false))
            }
        },
        [url]
    )

    React.useEffect(() => {
        fetchComponentData()
    }, [fetchComponentData])

    return {
        data,
        error,
        fetchData: fetchComponentData,
        loading,
    }
}

export default useFetch
