import React, {
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react'
import { OddsProvider } from '@sport1/news-styleguide/Bet'
import { debug, LogType } from '../Logger'

export type MatchDisclaimerProps = {
    matchId?: string
    uiState?: string
    needsRevenueDisclaimer?: boolean
    provider?: OddsProvider
}

type OddsDisclaimerContextType = {
    hasOdds: boolean
    needsDisclaimer: boolean
    provider?: OddsProvider
    registerMatch: (match: MatchDisclaimerProps) => void
    unregisterMatch: (matchId: string) => void
}

const OddsDisclaimerContext = createContext<OddsDisclaimerContextType>({
    hasOdds: false,
    needsDisclaimer: false,
    registerMatch: () => debug(LogType.silentException, 'no DisclaimerContextProvider'),
    unregisterMatch: () => debug(LogType.silentException, 'no DisclaimerContextProvider'),
})

type ProviderProps = {
    children?: ReactElement
}

export const OddsDisclaimerContextProvider = ({ children }: ProviderProps): ReactElement => {
    const [needsDisclaimer, setNeedsDisclaimer] = useState(false)
    const [hasOdds, setHasOdds] = useState(false)
    const activeProvider = useRef<OddsProvider>()

    const matchesNeedingRevenueDisclaimer = useRef(new Set<string>())
    const matchesWithOdds = useRef(new Set<string>())

    const registerMatch = useCallback(
        ({ matchId, needsRevenueDisclaimer, uiState, provider }: MatchDisclaimerProps) => {
            if (matchId && uiState === 'AVAILABLE') {
                matchesWithOdds.current.add(matchId)
                activeProvider.current = provider
                if (needsRevenueDisclaimer) {
                    matchesNeedingRevenueDisclaimer.current.add(matchId)
                }
                setHasOdds(matchesWithOdds.current.size > 0)
                setNeedsDisclaimer(matchesNeedingRevenueDisclaimer.current.size > 0)
            }
        },
        []
    )

    const unregisterMatch = useCallback((matchId: string) => {
        matchesWithOdds.current.delete(matchId)
        matchesNeedingRevenueDisclaimer.current.delete(matchId)
        setHasOdds(matchesWithOdds.current.size > 0)
        setNeedsDisclaimer(matchesNeedingRevenueDisclaimer.current.size > 0)
        activeProvider.current = undefined
    }, [])

    return (
        <OddsDisclaimerContext.Provider
            value={{
                hasOdds,
                needsDisclaimer,
                registerMatch,
                unregisterMatch,
                provider: activeProvider.current,
            }}
        >
            {children}
        </OddsDisclaimerContext.Provider>
    )
}

export const useOddsDisclaimerContext = (): OddsDisclaimerContextType =>
    useContext<OddsDisclaimerContextType>(OddsDisclaimerContext)
