import {
    BundesligaHubProps,
    ComponentType,
    EditorialHeroCardProps,
    HorizontalCardProps,
    TeamHorizontalCardProps,
    TeamTeaserCardProps,
    TeaserCardProps,
    TopTeaserCardProps,
    TrackingDataProps,
    UpcomingEventStreamProps,
} from '@sport1/types/web'
import { DASHBOARD_COMPONENT_TYPES } from '@/helpers/renderBaseChannelComponent'
import { mapTrackingData } from '@/utils/tracking/TrackingProvider'

type ComponentsWithTeaserTracking =
    | ComponentType.BUNDESLIGA_HUB
    | ComponentType.TEASER_CARD
    | ComponentType.HORIZONTAL_CARD
    | ComponentType.TOP_TEASER_CARD
    | ComponentType.EDITORIAL_HERO_CARD
    | ComponentType.TV_UPCOMING_EVENT_STREAMS
    | ComponentType.TEAM_VIDEO_CARD
    | ComponentType.TEAM_TEASER_CARD

type TrackedComponentType<T> = T extends ComponentType.BUNDESLIGA_HUB
    ? BundesligaHubProps
    : T extends ComponentType.TEASER_CARD
    ? TeaserCardProps
    : T extends ComponentType.HORIZONTAL_CARD
    ? HorizontalCardProps
    : T extends ComponentType.TOP_TEASER_CARD
    ? TopTeaserCardProps
    : T extends ComponentType.EDITORIAL_HERO_CARD
    ? EditorialHeroCardProps
    : T extends ComponentType.TV_UPCOMING_EVENT_STREAMS
    ? UpcomingEventStreamProps
    : T extends ComponentType.TEAM_VIDEO_CARD
    ? TeamHorizontalCardProps
    : T extends ComponentType.TEAM_TEASER_CARD
    ? TeamTeaserCardProps
    : never

const getFormat = (
    parentFormat = '',
    childFormat = ''
): { key: 'format'; value: string } | undefined => {
    if (parentFormat && childFormat) {
        return {
            key: 'format',
            value: `${parentFormat} | ${childFormat}`,
        }
    } else if (childFormat) {
        return {
            key: 'format',
            value: childFormat,
        }
    } else if (parentFormat) {
        return {
            key: 'format',
            value: parentFormat,
        }
    }
}

const getVariant = (
    parentVariant: string,
    dashboardPosition: number,
    teaserPosition: number
): { key: 'variant'; value: string } => {
    return {
        key: 'variant',
        value: `${dashboardPosition} | ${teaserPosition} | ${parentVariant}`,
    }
}

const addAndOverwriteTrackingData = (
    a: TrackingDataProps[],
    b?: TrackingDataProps[]
): TrackingDataProps[] => {
    if (b) {
        const result: TrackingDataProps[] = []
        // add non conflicting items from A
        a.forEach(itemA => {
            if (!b.find(itemB => itemB.key === itemA.key)) {
                result.push(itemA)
            }
        })
        // add all items from B
        result.push(...b)
        return result
    }
    return a
}

const getConflictFreeParentData = (
    childTrackingData: TrackingDataProps[],
    parentTrackingData?: TrackingDataProps[]
): TrackingDataProps[] => {
    const result: TrackingDataProps[] = []
    if (parentTrackingData) {
        parentTrackingData.forEach(parentItem => {
            if (
                parentItem.key !== 'variant' &&
                parentItem.key !== 'format' &&
                !childTrackingData.some(childItem => childItem.key === parentItem.key)
            ) {
                result.push(parentItem)
            }
        })
    }
    return result
}

type Props<T> = {
    componentType: T
    component: TrackedComponentType<T>
    dashboardPosition: number
    dashboardName?: string
    getAdditionalTrackingData?: () => TrackingDataProps[]
}

/**
 * This is a generic function and its output type depends on its input type.
 * For more information, especially about how to extend the type definition:
 *  -> ng / Apps / Donkey Kong
 *  -> src / hooks / useWidgetLayoutRefresh.ts
 * */
export const prepareForTeaserTracking = <T extends ComponentsWithTeaserTracking>({
    component,
    dashboardPosition,
    getAdditionalTrackingData,
    dashboardName,
}: Props<T>): TrackedComponentType<T> => {
    if (DASHBOARD_COMPONENT_TYPES.includes(component.type) && component.meta?.tracking) {
        component.meta.tracking = addAndOverwriteTrackingData(
            component.meta.tracking,
            getAdditionalTrackingData && getAdditionalTrackingData()
        )
        let teaserPosition: number = 1
        component.content?.forEach(item => {
            /** Skip Ad **/
            if ('ad' in item) {
                return
            }

            /**
             * If this component should have teaser tracking and the parent has tracking data, but not the child
             * we need to create the meta and tracking objects in the child.
             * */
            if (!('meta' in item && item.meta?.tracking)) {
                item.meta = {
                    tracking: [],
                }
            }
            if ('meta' in item && item.meta?.tracking) {
                /** Step 1: merge conflict-free tracking data */
                item.meta.tracking.push(
                    ...getConflictFreeParentData(item.meta.tracking, component.meta?.tracking)
                )

                const mappedParentTrackingData = mapTrackingData(component.meta?.tracking)
                const mappedChildTrackingData = mapTrackingData(item.meta.tracking)

                /** Step 2: create and add the new format value */
                if (mappedChildTrackingData.format) {
                    let format
                    if (mappedChildTrackingData?.format?.includes('|')) {
                        format = getFormat('', mappedChildTrackingData.format)
                    } else if (mappedParentTrackingData?.format?.includes('|')) {
                        format = getFormat(mappedParentTrackingData.format, '')
                    } else {
                        format = getFormat(
                            mappedParentTrackingData.format,
                            mappedChildTrackingData.format
                        )
                    }
                    if (format) {
                        const formatIndex = item.meta.tracking.findIndex(
                            item => item.key === 'format'
                        )
                        if (formatIndex >= 0) {
                            item.meta.tracking[formatIndex] = format
                        }
                    }
                } else if (mappedParentTrackingData.format) {
                    item.meta.tracking.push({
                        key: 'format',
                        value: `${mappedParentTrackingData.format}${
                            mappedParentTrackingData.format.includes('|') ? '' : ' |'
                        }`,
                    })
                }

                /** Step 3: create and add the new variant value */
                if (mappedParentTrackingData.variant && !mappedChildTrackingData.variant) {
                    item.meta.tracking.push(
                        getVariant(
                            mappedParentTrackingData.variant,
                            dashboardPosition,
                            teaserPosition++
                        )
                    )
                } else if (dashboardName) {
                    item.meta.tracking.push(
                        getVariant(dashboardName, dashboardPosition, teaserPosition++)
                    )
                }
            }
        })
    }
    return component
}
