import { TeaserProps } from '@sport1/types/web'

export const slugify = (str: string): string =>
    str
        ?.toLowerCase()
        .replace('ß', 'ss')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\W/g, '-')
        .replace(/--+/g, '-')

const getContentSlug = (item: TeaserProps): string | undefined =>
    item.title && item.id ? `${slugify(item.title)}__${item.id}` : undefined

const getVideoSlugBySharingUrl = (item: TeaserProps): string => {
    try {
        const url = new URL(item.meta?.sharingUrl ?? '')
        return url.pathname
    } catch (e) {}
    return ''
}

const getVideoSlugByTitle = (item: TeaserProps): string => {
    const slug = getContentSlug(item)
    return slug ? `/tv-video/video/${slug}` : ''
}

/**
 * - sharingUrl is not reliable, because it normalizes umlauts wrong (for now)
 * - title__id is not reliable, because the title might be a teaser title, not the content's title
 *
 * The caller knows which one fits best. So they may pass preferSharing.
 */
export const getVideoSlug = (item: TeaserProps, preferSharing = false): string => {
    if (preferSharing) {
        return getVideoSlugBySharingUrl(item) || getVideoSlugByTitle(item)
    }

    return getVideoSlugByTitle(item) || getVideoSlugBySharingUrl(item)
}

export const getGallerySlug = (item: TeaserProps): string => {
    const slug = getContentSlug(item)
    return slug ? `/galerie/${slug}` : ''
}

export const getArticleSlug = (item: TeaserProps): string => {
    if (item.url?.startsWith('/news')) {
        return item.url
    }
    if (item.slug?.startsWith('/news')) {
        return item.slug
    }
    if (item.slug) {
        return `/news${item.slug}`
    }
    return item.url
}
