import { TeamClientModel } from '@sport1/types/sportData'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react'

type ContextType = {
    team: TeamClientModel
    changeTeam: (team: TeamClientModel) => void
}

type ContextProps = {
    team: TeamClientModel
}

const Context = createContext<ContextType>({ team: {}, changeTeam: () => undefined })

const TeamContext: FC<ContextProps & PropsWithChildren> = ({ children, team: initialTeam }) => {
    const [team, setTeam] = useState<TeamClientModel>(initialTeam)

    const changeTeam = useCallback((team: TeamClientModel) => {
        setTeam(team)
    }, [])

    return <Context.Provider value={{ team, changeTeam }}>{children}</Context.Provider>
}

export const useTeamContext = () => useContext(Context)

export default TeamContext
