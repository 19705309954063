type ValueType = number | string

const getTotalStats = (
    template = 'S-U-N',
    won: ValueType = 0,
    drawn: ValueType = 0,
    lost: ValueType = 0
) => {
    return template.replace('S', `${won}`).replace('U', `${drawn}`).replace('N', `${lost}`)
}

export default getTotalStats
