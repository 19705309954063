import React, {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
} from 'react'
import { MatchTickerClientModel } from '@sport1/types/sportData'
import fetchMatch from '@/utils/liveticker/fetchMatch'
import getSportIdentifier from '@/utils/match/getSportIdentifier'

type LivetickerMatchProviderProps = {
    livetickerMatch?: MatchTickerClientModel | null
}

type LivetickerMatchState = Partial<Pick<MatchTickerClientModel, 'matchEvents' | 'match'>> & {
    refreshLivetickerMatch: () => void
}

const LivetickerMatchContext = createContext<LivetickerMatchState>({
    refreshLivetickerMatch: () => undefined,
})

export const LivetickerMatchProvider: FC<LivetickerMatchProviderProps & PropsWithChildren> = ({
    livetickerMatch: initialLivetickerMatch,
    children,
}) => {
    const [didLoad, setDidLoad] = React.useState(false)
    const [livetickerMatch, setLivetickerMatch] = React.useState<
        MatchTickerClientModel | undefined
    >()

    const refreshLivetickerMatch = useCallback(() => {
        if (!initialLivetickerMatch?.match) return
        const sportIdentifier = getSportIdentifier(initialLivetickerMatch.match)
        if (!sportIdentifier || !initialLivetickerMatch.match.id) return
        fetchMatch(sportIdentifier, initialLivetickerMatch.match.id)
            .then(response => {
                if (response.match) {
                    setLivetickerMatch(response)
                }
            })
            .catch(() => {
                // Do nothing
            })
            .finally(() => {
                setDidLoad(prevState => (!prevState ? true : prevState))
            })
    }, [initialLivetickerMatch])

    useEffect(() => {
        if (!livetickerMatch?.match) {
            refreshLivetickerMatch()
        }
    }, [livetickerMatch?.match, refreshLivetickerMatch])

    return (
        <LivetickerMatchContext.Provider
            value={{
                match: livetickerMatch?.match,
                matchEvents: livetickerMatch?.matchEvents,
                refreshLivetickerMatch,
            }}
        >
            {!initialLivetickerMatch || didLoad ? children : null}
        </LivetickerMatchContext.Provider>
    )
}

export const useLivetickerMatch = (): LivetickerMatchState =>
    useContext<LivetickerMatchState>(LivetickerMatchContext)
