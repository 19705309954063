const getTendency = (
    position: number,
    startPosition?: number
): 'UP' | 'DOWN' | 'UNCHANGED' | undefined => {
    if (startPosition === undefined || position === undefined) {
        return undefined
    }

    if (position === startPosition) return 'UNCHANGED'
    if (position > startPosition) return 'DOWN'
    return 'UP'
}

export default getTendency
